<template>
  <p class="price-was">
    <template v-if="showLabel">Retail: </template>
    <span v-else class="sr-only">Price was</span>
    <Price :amt="amt" />
  </p>
</template>

<script>
export default {
  name: 'RetailPrice',
  components: {
    Price: () => import('~/../common/components/Price.vue'),
  },
  props: {
    amt: {
      type: Number,
      required: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
